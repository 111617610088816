import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from '../helpers/images'

const SimpleCarousel = ({data}) => {

    var settings = {
        dots: true,
        infinite: true,
        centerMode: true,
        className: "center",
        centerPadding: "160px",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
                {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 1,
                        className: "",
                        centerMode: false,
                    }
                },
        ],
    };

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div className="arrow-slider-left" onClick={onClick}>
                <img src={img.ArrowLeftGray}/>
            </div>
        );
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div className="arrow-slider-right" onClick={onClick}>
                <img src={img.ArrowRightGray}/>
            </div>
        );
    }
    

    return (
        <>
            {
                data && data.length > 0 &&
                    
                <Slider
                    {...settings}
                >
                    {data.map((item, i) => {
                        return (
                            <div className="carousel-item" key={i}>
                                <div className="box-image-carousel">
                                    <img src={item?.fields?.file?.url} />
                                </div>

                                <h2>{item?.fields?.title}</h2>
                            </div>
                        )
                    })}
                </Slider>
            }
        </>
    );
};

export default SimpleCarousel;
