import {createClient} from "contentful";
import { Space, Token } from './env'

const clientTitle = createClient({
    space: Space,
    accessToken: Token
});

export const getSectionTitle = async (setInfo, setError)=> {
    try {
        const entries = await clientTitle.getEntries({
            content_type: "sectionTop",
            select: "fields"
        });
        setInfo(entries?.items[0]?.fields)
    } catch (error) {
        setError(error)
    }
}
