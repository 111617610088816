import React,{Component,} from "react";

import LogoInferior from '../images/logoInferior.svg';
import LogoSuperior from '../images/logoSuperior.svg';
import ArrowLeftGray from '../images/flecha-gris-izquierda.svg';
import ArrowRightGray from '../images/flecha-gris-derecha.svg';
import FullLogo from '../images/fullLogo.svg';
import CloseModal from '../images/closeModal.svg';
import PhoneMask from '../images/phone-mask.png';
import PenGif from '../images/lapizzz.gif';
import PixelsGif from '../images/pixeles_blancos.gif';


export default {
    LogoSuperior,
    LogoInferior,
    ArrowLeftGray,
    ArrowRightGray,
    FullLogo,
    CloseModal,
    PhoneMask,
    PenGif,
    PixelsGif
}
