import TopSection from '../src/sections/topSection'
import PhotoRealistic from '../src/sections/photoRealistic'
import PhotoSpecification from '../src/sections/photoSpecification'


function App() {
  return (
    <div className="App">
      <TopSection />
      <PhotoRealistic />
      <PhotoSpecification/>
    </div>
  );
}

export default App;
