import React, { useEffect, useState } from 'react'
import {getSectionBottomsSpecification, getPhotoCarousel} from '../modules/photoSpecificationActions'
import ModalCarousel from '../components/modal-carousel';
import SimpleCarousel from '../components/simple-carousel'
import { Link } from "react-scroll";
import img from '../helpers/images';

const PhotoSpecification = ({}) => {

    const [infoBottoms, setInfoBottoms] = useState({})
    const [error, setError] = useState({})
    const [selectorBottons, setSelectorBottoms] = useState(1)
    const [photosSpecification, setPhotosSpecification] = useState([])
    const [photosPlans, setPhotosPlans] = useState([])
    const [allPhotosSpecification, setAllPhotosSpecification] = useState([])
    const [allPhotosPlans, setAllPhotosPlans] = useState([])
    const [dataGrid, setDataGrid] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [dataSendModal, setDataSendModal] = useState({})
    const [dataSendCarousel, setDataSendCarousel] = useState([])

    useEffect(() => {
        getSectionBottomsSpecification(setInfoBottoms, setError)
        getPhotoCarousel(setPhotosSpecification, 'sectionSpecificationGridPhotos')
        getPhotoCarousel(setPhotosPlans, 'sectionPlansGridPhotos')
    }, [])

    useEffect(() => {
        if (photosSpecification.length > 0) {
            functionCreatePhotosSpecification(photosSpecification)
        }
        if (photosPlans.length > 0) {
            functionCreatePhotosPlans(photosPlans)
        }
    }, [photosSpecification, photosPlans])


    const selector=(value)=> {
        setSelectorBottoms(value)
        if (value === 1) {
            setDataGrid([])
            setDataGrid(allPhotosSpecification)
            setDataSendCarousel(allPhotosSpecification)
        } else {
            setDataGrid([])
            setDataGrid(allPhotosPlans)
            setDataSendCarousel(allPhotosPlans)
        }
    }

    const functionCreatePhotosSpecification =(data)=> {
        var interArray = []
        for (let i = 0; i < data.length; i++) {
            var photoGroup = data[i].fields.photos
            if (i === 0) {
                interArray = [...photoGroup]
            } else {
                interArray = [...interArray, ...photoGroup]
            }
        }
        setAllPhotosSpecification([...interArray])
        setDataSendCarousel([...interArray])
    }

    const functionCreatePhotosPlans =(data)=> {
        var interArray = []
        for (let i = 0; i < data.length; i++) {
            var photoGroup = data[i].fields.photos
            if (i === 0) {
                interArray = [...photoGroup]
            } else {
                interArray = [...interArray, ...photoGroup]
            }
        }
        setAllPhotosPlans([...interArray])
    }

    const showModalByClick =(data)=> {
        setOpenModal(!openModal)
        setDataSendModal(data)
    }

    const returnFunction =()=> {
        if (selectorBottons === 1) {
            return (
                <Link
                    className="return-button"
                    onClick={()=>selector(2)}
                    to="photoSpecificationSection"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    Ver Planos de detalle
                </Link>
            )
        } else {
            return (
                <Link
                    className="return-button"
                    onClick={()=>selector(1)}
                    to="photoSpecificationSection"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    Ver Dibujos arquitectónicos
                </Link>
            )
        }
    }


    return (
        <section className="photo-specification-section" id="photoSpecificationSection">
            <h2 className="text-center mb50 position-up">{infoBottoms?.title}</h2>
            <div className="grid-bottoms">
                <img className="gif-pen" src={img.PenGif} alt="trazzo" />
                <Link
                    className="bottom-image"
                    onClick={()=>selector(1)}
                    to="sectionSpecification"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    <img src={infoBottoms?.imageBottom1?.fields?.file?.url} alt={infoBottoms?.imageBottom1?.fields?.description}/>
                    <div className="center-text-info">
                        <h3>{infoBottoms?.titleFinishes}</h3>
                    </div>
                </Link>

                <Link
                    className="bottom-image"
                    onClick={()=>selector(2)}
                    to="sectionSpecification"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    <img src={infoBottoms?.imageBottom2?.fields?.file?.url} alt={infoBottoms?.imageBottom2?.fields?.description}/>
                    <div className="center-text-info">
                        <h3>{infoBottoms?.titlePlans}</h3>
                    </div>
                </Link>
            </div>
            
            
            <div id="sectionSpecification">
                <h2 className="text-center mb50">{selectorBottons === 1 ? infoBottoms?.titleFinishes : infoBottoms?.titlePlans}</h2>
                <div className="box-paragraph">
                    <p>{selectorBottons === 1 ? infoBottoms?.descriptionFinishes : infoBottoms?.descriptionPlans}</p>
                </div>

                <SimpleCarousel
                    data={dataSendCarousel}
                />

                {returnFunction()}
            </div>
        </section>
    );
};

export default PhotoSpecification;
