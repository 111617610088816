import React, { useEffect, useState } from 'react'
import {getSectionTitle} from '../modules/topActions'
import {Animated} from "react-animated-css";
import img from '../helpers/images';
import { Link } from "react-scroll";
import LazyLoad from 'react-lazy-load';

const TopSection = ({}) => {

    const [infoTop, setInfoTop] = useState({})
    const [error, setError] = useState({})

    useEffect(() => {
        getSectionTitle(setInfoTop, setError)
    }, [])


    return (
        <section className="top-section" id="topSection">
            <div className="grid-animated">
                <h2 className="text-center">{infoTop && infoTop.titleTop}</h2>
                <Animated
                    animationIn="bounceInLeft"
                    animationOut="fadeOut"
                    isVisible={true}
                    animationInDelay={200}
                    animationInDuration={3000}
                    className="d-flex margin-auto justify-center"
                >
                    <Link
                        to="photoRealisticSection"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        className="box-logo1">
                        <img src={img.LogoSuperior} alt="trazzo"/>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={5100} >
                            <div className="content-text1">{infoTop && infoTop.textLogo1}</div>
                        </Animated>
                    </Link>
                </Animated>

                <Animated
                    animationIn="bounceInRight"
                    animationOut="fadeOut"
                    isVisible={true}
                    animationInDelay={1200}
                    animationInDuration={3000}
                    className="d-flex margin-auto justify-center"
                >
                    <Link
                        className="box-info"
                        to="photoSpecificationSection"
                        spy={true}
                        smooth={true}
                        offset={200}
                        duration={1500}
                    >
                        <div className="box-logo2">
                            <img src={img.LogoInferior} alt="trazzo"/>
                            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={6000} >
                                <div className="info-box">{infoTop && infoTop.textLogo2}</div>
                            </Animated>
                        </div>
                    </Link>
                </Animated>
            </div>


            <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={true}
                animationInDelay={5300}
            >
                <div className="grid-section-video">
                    <div className="logo-top">
                        <LazyLoad offset={300}>
                            <img className="img-fluid" src={img.FullLogo} alt="trazzo" />
                        </LazyLoad>

                    </div>
                    <div className="box-video">
                        <img className="img-video" src={img.PhoneMask} alt="trazzo" />
                        <div className="video">
                            {
                                infoTop?.video?.fields?.file?.url ?
                                    <video
                                        className="video-frame"
                                        autoPlay={true}
                                        loop={true}
                                        muted={true}
                                        playsInline={true}
                                    >
                                        <source
                                            src={`https:${infoTop?.video?.fields?.file?.url}`}
                                            type="video/mp4"
                                        />
                                    </video>
                                    :
                                    undefined
                            }

                        </div>
                    </div>
                    <div className="grid-row20 pad-section-paragraph">
                        <p>
                            {infoTop && infoTop.paragraph}
                        </p>
                        <p>
                            {infoTop && infoTop.paragraph2}
                        </p>
                    </div>
                </div>
            </Animated>
        </section>
    );
};

export default TopSection;
