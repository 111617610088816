import React, { useEffect, useState } from 'react'
import {getSectionBottoms, getPhotoCarousel} from '../modules/photoRealisticActions'
import SimpleCarousel from '../components/simple-carousel'
import LazyLoad from 'react-lazy-load';
import { Link } from "react-scroll";
import img from '../helpers/images';

const PhotoRealistic = ({}) => {

    const [infoBottoms, setInfoBottoms] = useState({})
    const [error, setError] = useState({})
    const [selectorBottons, setSelectorBottoms] = useState(1)
    const [photosRealistic, setPhotosRealistic] = useState([])
    const [photosSketch, setPhotosSketch] = useState([])
    const [allPhotosRealistic, setAllPhotosRealistic] = useState([])
    const [allPhotosSketch, setAllPhotosSketch] = useState([])
    const [dataSendCarousel, setDataSendCarousel] = useState([])

    useEffect(() => {
        getSectionBottoms(setInfoBottoms, setError)
        getPhotoCarousel(setPhotosRealistic, 'sectionRealisticCarousel')
        getPhotoCarousel(setPhotosSketch, 'sectionSketchCarousel')
    }, [])

    useEffect(() => {
        if (photosRealistic.length > 0) {
          functionCreatePhotosRealistic(photosRealistic)
        }
        if (photosRealistic.length > 0) {
            functionCreatePhotosSketch(photosSketch)
        }
    }, [photosRealistic, photosSketch])


    const selector=(value)=> {
        setSelectorBottoms(value)
        if (value === 1) {
            setDataSendCarousel(allPhotosRealistic)
        } else {
            setDataSendCarousel(allPhotosSketch)
        }

    }

    const functionCreatePhotosRealistic =(data)=> {
        var interArray = []
        for (let i = 0; i < data.length; i++) {
            var photoGroup = data[i].fields.photos
            if (i === 0) {
                interArray = [...photoGroup]
            } else {
                interArray = [...interArray, ...photoGroup]
            }
        }
        setAllPhotosRealistic([...interArray])
        setDataSendCarousel([...interArray])
    }

    const functionCreatePhotosSketch =(data)=> {
        var interArray = []
        for (let i = 0; i < data.length; i++) {
            var photoGroup = data[i].fields.photos
            if (i === 0) {
                interArray = [...photoGroup]
            } else {
                interArray = [...interArray, ...photoGroup]
            }
        }
        setAllPhotosSketch([...interArray])
    }

    const returnFunction =()=> {
        if (selectorBottons === 1) {
            return (
                <Link
                    className="return-button"
                    onClick={()=>selector(2)}
                    to="photoRealisticSection"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                   Ver Sketch
                </Link>
            )
        } else {
            return (
                <Link
                    className="return-button"
                    onClick={()=>selector(1)}
                    to="photoRealisticSection"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    Ver Render
                </Link>
            )
        }


    }

    return (
        <section className="photo-realistic-section" id="photoRealisticSection">
            <img className="gif-pixels" src={img.PixelsGif} alt="trazzo" />
            <h2 className="text-center mb50 up-position">{infoBottoms?.title}</h2>
            <div className="grid-bottoms">
                <Link
                    className="bottom-image"
                    onClick={()=>selector(1)}
                    to="sectionInfoRealistic"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    <LazyLoad  offset={300} className="h100">
                        <img src={infoBottoms?.imageBottom1?.fields?.file?.url} alt={infoBottoms?.imageBottom1?.fields?.description}/>
                    </LazyLoad>

                    <div className="center-text-info">
                        <h3>{infoBottoms?.imageBottom1?.fields?.title}</h3>
                    </div>
                </Link>

                <Link
                    className="bottom-image"
                    onClick={()=>selector(2)}
                    to="sectionInfoRealistic"
                    spy={true}
                    smooth={true}
                    offset={200}
                    duration={1500}
                >
                    <LazyLoad  offset={300} className="h100">
                        <img src={infoBottoms?.imageBottom2?.fields?.file?.url} alt={infoBottoms?.imageBottom2?.fields?.description}/>
                    </LazyLoad>
                    <div className="center-text-info">
                        <h3>{infoBottoms?.imageBottom2?.fields?.title}</h3>
                    </div>
                </Link>
            </div>

            <div id="sectionInfoRealistic" className="up-position">
                <h2 className="text-center mb50">{selectorBottons === 1 ? infoBottoms?.titleRealistic : infoBottoms?.titleSketch}</h2>
                <div className="box-paragraph">
                    <p>{selectorBottons === 1 ? infoBottoms?.descriptionRealistic : infoBottoms?.descriptionSketch}</p>
                </div>
                <SimpleCarousel
                    data={dataSendCarousel}
                />
            </div>

            {returnFunction()}
        </section>
    );
};

export default PhotoRealistic;
